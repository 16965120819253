.box {
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: 0.1s ease-in-out;
}
.overlay:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.overlay h3,
.overlay h4 {
  text-align: start;
  align-self: flex-start;
  text-transform: none;
  color: white;
}
.overlay h4 {
  font-weight: 400;
}
.head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-end;
}
.head img {
  width: 50px;
  height: 50px;
  background-color: white;
  padding: 12px;
  border-radius: 50px;
}
.foot {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.pill {
  padding: 10px 15px 10px 15px;
  background-color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
}
@media (max-width: 767px) {
  .pill {
    min-width: 112px;
  }
}
