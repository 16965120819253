.main {
  width: 100%;
  height: 75vh;
  background-color: #fafafa;
}
.hero {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.contentCont h1,
.contentCont h3 {
  align-self: center;
  text-align: center;
}
@media (max-width: 767px) {
  .main {
    height: 55vh;
  }
}
