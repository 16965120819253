.desktopHeader {
  padding: 40px 0px 0px 0px;
  width: 100%;
}
.mobileHeader {
  display: none;
  width: 100%;
}
.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.logo {
  display: flex;
  justify-content: start;
  align-items: center;
}
.logo img {
  width: 185px;
}
.tabs {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.36);
  background-color: rgba(255, 255, 255, 0.032);
  backdrop-filter: blur(24px);
  padding: 0px 7px 0px 7px;
}
.tabsBlack {
  border: 1px solid #a0a0a0;
}
.tab {
  padding: 10px 0px 13px 0px;
}
.activeTab {
  position: relative;
}
.activeTab:before {
  content: "";
  position: absolute;
  left: 25%;
  bottom: 0;
  height: 100%;
  width: 50%; /* or 100px */
  border-top: 2px solid rgba(27, 219, 186, 1);
}
.link {
  padding: 8px 28px 8px 28px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: 0.2s ease;
  font-family: "MyFontLight";
  border-radius: 25px;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}
.link:hover {
  color: rgb(224, 224, 224);
}
.blackLink {
  color: black;
}
.blackLink:hover {
  color: rgb(120, 120, 120);
}
.activeLink {
  background-color: rgba(27, 219, 186, 1);
  color: white;
}
.activeLink:hover {
  background-color: rgba(27, 219, 186, 1);
  color: white;
}
.solutions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 6px;
}
.solutions img {
  transform: rotate(180deg);
  width: 13px;
  height: 13px;
  transition: transform 0.3s ease;
}
.solutions:hover img {
  transform: rotate(0deg);
}
.drop_down:hover ~ .solutions img {
  transform: rotate(0deg);
}
.main a {
  text-decoration: none;
}
.drop_down {
  width: 75%;
  display: inline-block;
  margin-top: 105px;
  position: fixed;
  top: 0;
  z-index: 7;
}
.drop_downContent {
  margin-top: 20px;
  background-image: linear-gradient(to right, #1e1e1e, #1e1e1e);
  box-shadow: 5px 5px 50px #02020218;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 1;
  animation: fadeInFromNone 0.5s ease-out;
  overflow: hidden;
}
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
.listCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.listCol h4 {
  text-align: start;
  align-self: flex-start;
  color: #ffffff;
  text-transform: none;
}
.listCol a {
  font-family: "MyFontLight";
  align-self: flex-start;
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.listColLink {
  color: #ffffff;
}
.listColLinkActive {
  color: rgba(27, 219, 186, 1);
}
.listCol a:hover {
  color: #e8e8e8;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #fafafa;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 60px 50px 60px 50px;
  overflow: hidden;
}
.head {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.head img {
  width: 24px;
  height: 24px;
  align-self: center;
}
.head h4 {
  color: rgb(0, 0, 0);
  text-align: start;
  align-self: flex-start;
}
.box p {
  color: rgb(0, 0, 0);
  text-align: start;
  align-self: flex-start;
}
.box a {
  align-self: flex-start;
}
.box button {
  align-self: flex-start;
  font-family: "MyFontLight";
  font-size: 13px;
  font-weight: 600;
  padding: 9px 16px 9px 16px;
  letter-spacing: 0.5px;
  margin: 0;
  border-radius: 8px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  transition: 0.2s ease-in;
}
.box button:hover {
  background-color: #1BDBBA;
  color: white;
  border: 1px solid white;
}

@media (max-width: 1200px) {
  .logo {
    gap: 30px;
  }
  .desktopHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
  }
}
