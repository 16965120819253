.projectsCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.projects {
  width: 145px;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid rgba(27, 219, 186, 1);
  border-radius: 50%;
  padding: 22px;
  cursor: pointer;
}
.projects h4,
.projects h4 span {
  color: rgba(27, 219, 186, 1);
}
.projects h4 span {
  text-decoration: 3px underline;
  text-underline-offset: 6px;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box img {
  width: 100%;
  max-height: 520px;
  max-width: 850px;
}
.box p,
.box h2 {
  text-align: start;
  align-self: flex-start;
  padding-right: 60px;
}
.box p {
  font-weight: 600;
}
@media (max-width: 992px) {
  .projects {
    width: 108px;
    height: 108px;
    padding: 18px;
  }
}
