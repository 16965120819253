.contentCol h3,
.contentCol h1,
.contentCol p,
.contentCol .socials {
  text-align: start;
  align-self: flex-start;
  text-transform: none;
}
.contentCol img {
  width: 100%;
}
.boxCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.box {
  width: 100%;
  height: 49%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(30, 30, 30, 1);
  border-radius: 10px;
  padding: 40px 30px 40px 30px;
  transition: 2s ease-in-out;
  cursor: pointer;
}
.box h3 {
  color: white;
  text-transform: none;
}
.box p {
  color: rgba(205, 205, 205, 1);
}
.box a {
  width: 100%;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 2px solid #ffffff;
  padding: 4px;
  border-radius: 25px;
  cursor: pointer;
  align-self: flex-end;
}
.footer svg {
  border-radius: 50%;
  border: 2px solid #ffffff;
  padding: 7px;
  width: 40px;
  height: 40px;
}
.box:hover .footer svg {
  background-color: #ffffff;
}
.box:hover .footer svg g path {
  stroke: black;
}
.footer h4 {
  color: white;
}

@media (max-width: 992px) {
  .box {
    gap: 30px;
  }
}
