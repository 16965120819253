* {
  user-select: none;
}
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-thumb {
  background-color: #16d2b1;
  border-radius: 2px;
}
*::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  user-select: none;
}
.heading_capital {
  font-family: "MyFont";
  font-size: 70px;
  color: #262424;
  font-weight: 900;
  margin: 0;
}
.heading_capital span {
  background: linear-gradient(to right, #262424, #686262, #262424);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.h1_main {
  font-family: "MyFont";
  font-size: 56px;
  color: #262424;
  font-weight: 800;
  margin: 0;
}
.h1_main span {
  background: linear-gradient(to right, #262424, #79797a, #262424);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.h1_small {
  font-family: "MyFont";
  font-size: 34px;
  color: #262424;
  font-weight: 800;
  margin: 0;
}
.h1_small span {
  background: linear-gradient(to right, #262424, #79797a, #262424);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.h1_white_gradient {
  background: linear-gradient(
    84.4deg,
    #ffffff 1.6%,
    rgba(255, 255, 255, 0.65) 31.82%,
    #ffffff 67.87%,
    rgba(255, 255, 255, 0.55) 103.4%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.h2_main {
  font-family: "MyFont";
  font-size: 26px;
  color: #17191a;
  font-weight: 900;
  margin: 0;
}
.h2_main span {
  background: linear-gradient(to right, #262424, #79797a, #262424);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.h3_main,
.h3_main_white_gradient {
  font-family: "MyFontLight";
  font-size: 20px;
  color: rgba(27, 219, 186, 1);
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin: 0;
}
.h3_main span {
  color: rgba(0, 0, 0, 1);
}
.h3_main_white_gradient {
  font-size: 16px;
  background: linear-gradient(
    84.4deg,
    #ffffff 1.6%,
    rgba(255, 255, 255, 0.6) 31.82%,
    #ffffff 67.87%,
    rgba(255, 255, 255, 0.6) 103.4%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.h4_main {
  font-family: "MyFont";
  font-size: 17px;
  color: #17191a;
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.02cm;
}
.h4_main span {
  color: rgba(0, 0, 0, 1);
}
.h5_main {
  font-family: "MyFontRegular";
  font-size: 15px;
  color: #17191a;
  margin: 0;
}
.para_main {
  font-size: 16px;
  font-family: "MyFontRegular";
  text-align: start;
  color: #6f7071;
  font-weight: 400;
  margin: 0;
}
.para_main span {
  color: rgba(27, 219, 186, 1);
}
.button_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-radius: 8px;
  padding: 19px 32px 19px 32px;
  border: none;
  background: linear-gradient(
    to right,
    rgba(27, 219, 186, 1),
    rgba(27, 219, 187, 0.874)
  );
  color: white;
  font-family: "MyFont";
  font-size: 15px;
  transition: 0.2s ease-in-out;
}
.button_main:hover {
  background: linear-gradient(
    to right,
    rgba(27, 219, 187, 0.986),
    rgba(27, 219, 187, 0.605)
  );
}
.button_simple {
  border-radius: 25px;
  padding: 10px 20px 10px 20px;
  border: none;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-family: "MyFontLight";
  font-size: 14px;
  transition: 0.2s ease;
}
.button_simple:hover {
  border-color: rgba(255, 255, 255, 0.85);
  color: rgba(255, 255, 255, 0.85);
}
.black {
  border-color: rgb(24, 24, 24);
  color: rgb(24, 24, 24);
}
.black:hover {
  border-color: rgba(24, 24, 24, 0.6);
  color: rgba(24, 24, 24, 0.6);
}
.grey {
  transition: 0.2s ease-in-out;
  background: linear-gradient(to right, #666666, #666666);
}
.grey:hover {
  background: linear-gradient(to right, #666666f7, #666666b4);
}
@media (max-width: 767px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
  .main {
    padding: 60px 0px 60px 0px;
  }
  .heading_capital {
    font-size: 25px;
  }
  .heading_capital span {
    font-size: 30px;
  }
  .h1_main {
    font-size: 23px;
  }
  .h1_small {
    font-size: 21px;
  }
  .h2_main {
    font-size: 18px;
  }
  .h3_main {
    font-size: 15px;
  }
  .h3_main_white_gradient {
    font-size: 11px;
  }
  .h4_main {
    font-size: 13px;
  }
  .h5_main {
    font-size: 12px;
  }
  .para_main {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .heading_capital {
    font-size: 23px;
  }
  .heading_capital span {
    font-size: 26px;
  }
  .heading_main {
    font-size: 22px;
  }
}

/* form */

.form-container {
  background-color: #000000;
}
.po23 {
  margin-top: 90px;
}
.form-iconn {
  width: 130px;
  display: inline-flex;
  background-color: #504f4f !important;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 30px;
}

.btnnn {
  display: inline-block;
  width: 100%;
  background-color: #1bdbba;
  color: aliceblue;
  text-align: center;
}
.btnnn:hover {
  background-color: #1bdbba;
  color: aliceblue;
  text-align: center;
}
.colr {
  font-size: 16px;
  color: #1bdbba;
}
.text-c {
  font-weight: 500;
  font-size: 30px;
  color: aliceblue;
}
.text-c2-p {
  color: #959393;
  font-size: 15px;
  font-weight: 400;
}
.text-c2 {
  color: #3a3939;
  font-size: 15px;
  font-weight: 400;
}
.text-areaa {
  height: 100px !important;
}

/* Our Strategic Partners section */
.Our-stra {
  font-weight: 600;
  font-size: 80px !important;
  color: #060606;
  font-family: "MyFont";
}

.Our-stra-p {
  font-weight: 400 !important;
  font-size: 20px !important;
  font-family: "MyFont";
  color: #343434 !important;
}
.btn-c {
  color: #eceef0;
  font-size: 16px;
  font-weight: 600;
  background-color: #666666;
  padding: 15px 28px;
}
.btn-c:hover {
  background-color: #666666;
  color: #eceef0;
}

@media (max-width: 564px) {
  .Our-stra {
    font-weight: 600;
    font-size: 40px !important;
    color: #060606;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }

  .Our-stra-p {
    font-weight: 400 !important;
    font-size: 20px !important;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: #343434 !important;
  }
  .text-2 {
    font-weight: 500 !important;
    font-size: 20px;
    color: #fff;
  }
}

/* @media (max-width: 992px) {
  .position-text{
    margin-top: 30px;
    position: absolute;
    top: 90px!important;
    transform: translateY(25%);
  }
  .header-sec-1{

   height: 800px;
   background-size:auto;
    
  }
  .text-2{
    font-weight: 600;
    font-size: 50px;
    color: #fff;
  }

} */

/* card */
.part {
  font-family: "MyFont";
  font-size: 20px;
  font-weight: 600;
  color: #1d1c1c;
}
.trust {
  font-family: "MyFont";
  color: #666666;
}

/* About */

.About_TC {
  color: #1bdbba;
  font-size: 28px;
  font-weight: 400;
  font-family: "MyFont";
}

/* Odooo */
.feedbackk {
  font-size: 18px;
  font-weight: 600;
  font-family: "MyFont";
  color: #1d1c1c;
}
.titlee {
  font-size: 18px;
  font-weight: 600;
  font-family: "MyFont";
  color: #6b6969;
}
