.main {
  width: 100%;
  height: auto;
}
.footer {
  padding: 80px 0px 45px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: rgba(23, 25, 26, 1);
}
.imgCol,
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.imgCol img {
  height: 100%;
  width: 100%;
}
.contentCol h3,
.contentCol h1,
.contentCol .pill,
.contentCol a {
  align-self: flex-start;
  text-align: start;
  text-decoration: none;
}
.contentCol a {
  width: 100%;
}
.pill {
  max-width: 360px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 1.8px solid rgba(255, 255, 255, 1);
  padding: 4px;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.2s ease-in;
}
.pill h4 {
  color: rgba(255, 255, 255, 1);
}
.pill:hover {
  border-color: rgba(255, 255, 255, 0.58);
}
.pill img {
  border-radius: 50%;
  border: 1.8px solid rgba(255, 255, 255, 1);
  padding: 7px;
  width: 40px;
  height: 40px;
}
.pill:hover img {
  border-color: rgba(255, 255, 255, 0.58);
}
.socialCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  flex-direction: column;
  gap: 15px;
}
.socials {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}
.social {
  width: 55px;
  height: 55px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social:hover {
  background-color: #3e3e3e;
}
.social img {
  width: 22px;
  height: 22px;
}
.linkCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 30px;
}
.linkCol p {
  color: rgba(158, 158, 159, 1);
}
.linkCol a {
  text-decoration: none;
  text-align: start;
  align-self: flex-start;
}
.linkCol a p {
  color: white;
}
.bottomRow {
  padding: 30px 0px 30px 0px;
  width: 100%;
  background-color: #ffffff;
}
.bottomRowInner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 992px) {
  .footer {
    padding: 60px 0px 35px 0px;
  }
  .contentCol h3,
  .contentCol h1,
  .contentCol .pill,
  .contentCol a {
    align-self: center;
    text-align: center;
  }
  .contentCol a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pill {
    width: 80%;
  }
  .imgCol img {
    max-width: 125px;
    max-height: 125px;
  }
  .linkCol {
    padding-top: 0px;
    padding-left: 0px;
  }
  .linkCol p,
  .linkCol a {
    text-align: center;
    align-self: center;
  }
  .socialCol {
    align-items: center;
    justify-content: center;
  }
  .socialCol p {
    text-align: center;
    align-self: center;
  }
  .social {
    width: 40px;
    height: 40px;
    padding: 7px;
  }
  .bottomRowInner {
    flex-direction: column;
    gap: 10px;
  }
  .bottomRowInner p {
    width: 100%;
    align-self: center;
    text-align: center;
  }
}
