.main {
  width: 100%;
  height: 100%;
}
.innerMain {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 80px;
  border-radius: 10px;
}
.contentCol,
.formCol form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCol h3,
.contentCol h1,
.contentCol p,
.contentCol .socials {
  text-align: start;
  align-self: flex-start;
  text-transform: none;
}
.contentCol h1 {
  font-size: 34px;
  color: rgba(255, 255, 255, 1);
}
.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: rgba(52, 52, 52, 1);
  border-radius: 25px;
  padding: 13px 20px 13px 20px;
  gap: 15px;
}
.socials svg {
  width: 22px;
  height: 22px;
  fill: white;
  transition: 0.2s ease-in-out;
}
.socials a:hover svg {
  fill: rgba(27, 219, 186, 1);
}
.formCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 40px 28px 40px 28px;
}
.formCol h3 {
  align-self: flex-start;
  text-align: start;
  text-transform: none;
}
.formCol form input,
.formCol form textarea {
  width: 100%;
  border: none;
  border: 2px solid rgba(184, 184, 184, 0.45);
  background-color: transparent;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  color: #b5b5b5;
  padding: 18px;
}
.formCol form input:focus,
.formCol form textarea:focus {
  outline: none;
}
.formCol form label {
  font-size: 18px;
  text-align: start;
  align-self: flex-start;
  font-family: "MyFont";
  font-weight: 500;
  padding-bottom: 10px;
}
.formCol form button {
  width: 100%;
}
.formCol form button img {
  padding-left: 4px;
  width: 25px;
}
@media (max-width: 767px) {
  .innerMain {
    padding: 40px 25px 40px 25px;
  }
  .contentCol h1 {
    font-size: 20px;
  }
  .formCol {
    padding: 25px 18px 25px 18px;
  }
  .formCol form input,
  .formCol form textarea {
    padding: 12px;
  }
  .formCol form label {
    font-size: 14px;
    padding-bottom: 10px;
  }
}
