.reviews_swiper {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.reviews_swiper .swiper-slide {
  background-color: #eceded;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  padding: 25px;
}
.custom-prev-button,
.custom-next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #000;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  width: 75px;
}
@media (max-width: 992px) {
  .custom-prev-button,
  .custom-next-button {
    padding: 5px;
    width: 45px;
  }
}
