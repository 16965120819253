.main {
  padding: 50px 0px 50px 0px;
  width: 100%;
}
.imgCol,
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.imgCol img {
  width: 100%;
}
.contentCol a,
.contentCol div,
.contentCol h1,
.contentCol h2,
.contentCol h3,
.contentCol p {
  align-self: flex-start;
  text-align: start;
  text-decoration: none;
}
.contentCol button img {
  padding-left: 4px;
  width: 25px;
}
.listItem {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.listItem img {
  align-self: center;
}
.pillButton {
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 2px solid #17191a;
  padding: 4px;
  border-radius: 25px;
  cursor: pointer;
}
.pillButton img {
  border-radius: 50%;
  border: 2px solid #17191a;
  padding: 7px;
  width: 40px;
  height: 40px;
}
.pillButton:hover {
  border: 2px solid rgb(128, 128, 128);
}
.pillButton:hover img {
  background-color: white;
}
@media (max-width: 992px) {
  .main {
    padding: 30px 0px 30px 0px;
  }
  .imgCol img {
    align-self: center;
  }
  .contentCol {
    gap: 12px;
    justify-content: start;
    align-items: start;
  }
  .pillButton {
    min-width: 200px;
  }
}
