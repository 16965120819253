.main {
  padding: 100px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-blend-mode: overlay;
  background-color: rgba(59, 59, 59, 0);
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.contentCol h1 {
  color: white;
}
.contentCol h2 {
  font-weight: 600;
  padding-bottom: 10px;
}
.contentCol img {
  max-width: 50px;
  max-height: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .main {
    min-height: 370px;
  }
  .contentCol h2 {
    padding-bottom: 0px;
  }
  .contentCol img {
    width: 40px;
    height: 40px;
  }
}
