.boxCont {
  width: 100%;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-height: 315px;
  border: 1px solid black;
  padding: 25px;
}
.box img {
  width: 70px;
  height: 70px;
  padding: 16px;
  background-color: black;
  border-radius: 2px;
}
.box h3 {
  color: black;
  text-transform: none;
}
.box img,
.box h1,
.box h3 {
  align-self: flex-start;
  text-align: start;
}
.reviewCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.buttonsCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 170px;
}
@media (max-width: 992px) {
  .reviewCont {
    gap: 30px;
  }
  .buttonsCont {
    width: 100px;
  }
}
