.box {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.box h2,
.box p,
.box img {
  align-self: flex-start;
  text-align: start;
}
.box img {
  background-color: black;
  padding: 14px;
  width: 60px;
  height: 60px;
}
