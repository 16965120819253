.main {
  padding: 100px 0px 100px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main h1 {
  font-weight: 900;
}
.headingCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.box h1 {
  font-size: 70px;
}
.box h4 {
  align-self: center;
  text-align: center;
  text-transform: none;
}
@media (max-width: 992px) {
  .box h1 {
    font-size: 50px;
  }
  .main {
    padding: 40px 0px 40px 0px;
  }
}
@media (max-width: 480px) {
  .box h1 {
    font-size: 40px;
  }
}
