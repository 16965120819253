.main {
  width: 100%;
  padding: 100px 0px 100px 0px;
}
.rowCont {
  padding-top: 20px;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  transition: 0.5s ease;
}
.padding {
  padding-top: 120px;
}
.swiperSlide {
  padding-top: 30px;
}
.imgbackgroundCont {
  width: 100%;
  max-height: 420px;
  min-height: 420px;
}
.imgBackground {
  box-shadow: 0px 2px 1px rgba(32, 32, 32, 0.169);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 0.2s ease;
}
.imgBackground:hover {
  transform: scale(1.02);
}
.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 20px;
}
.head div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.head div svg {
  width: 50px;
  height: 50px;
  fill: #17191a;
  border-radius: 50%;
  border: 3px solid #17191a;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s ease;
}
.head div svg:hover {
  background-color: #17191a;
  fill: white;
}
.head h2 {
  align-self: flex-start;
  text-align: start;
  text-transform: none;
}
.head h4 {
  font-size: 13px;
  text-transform: none;
}
.readMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding-top: 60px;
}
.readMore div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 2px solid rgb(10, 189, 158);
  cursor: pointer;
  transition: 0.3s ease;
  box-shadow: 0px 0px 15px rgba(130, 130, 130, 0.32);
  border-radius: 25px;
  padding: 12px 18px 12px 18px;
  gap: 15px;
  background-color: rgb(10, 189, 158);
  width: 300px;
}
.readMore div:hover {
  background-color: rgb(255, 255, 255);
}
.readMore h2 span {
  color: #fff;
  text-transform: none;
}
.readMore div:hover h2 span {
  color: rgb(10, 189, 158);
}
.readMore svg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  fill: #fff;
  background-color: transparent;
}
.readMore div:hover svg {
  fill: rgb(10, 189, 158);
}
@media (max-width: 1200px) {
  .imgbackgroundCont {
    min-height: auto;
  }
}
@media (max-width: 992px) {
  .main {
    padding: 40px 0px 40px 0px;
  }
  .main h1 {
    padding: 0 2% 0px 2%;
  }
  .rowCont {
    padding-top: 15px;
    gap: 0px;
  }
  .padding {
    padding-top: 0px;
  }
  .swiperSlide {
    padding-top: 0px;
  }
  .readMore {
    padding-top: 25px;
  }
  .readMore div {
    width: 100%;
  }
  .box {
    padding: 16px;
  }
}
@media (max-width: 480px) {
  .main h3 {
    font-size: 15px;
    padding-bottom: 10px;
  }
  .head h2 {
    font-size: 20px;
  }
}
