.formCol form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
}
.formCol h1,
.formCol p,
.contentCol h1,
.contentCol h2 {
  align-self: flex-start;
  text-align: start;
}
.formCol form {
  gap: 40px;
}
.formCol form input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(225, 225, 225, 1);
  font-family: "MyFontLight";
  font-weight: 500;
  color: #000000;
  padding: 0px 0px 18px 0px;
}
.formCol form input::placeholder {
  color: #000000;
  font-weight: 500;
}
.formCol form input:focus {
  outline: none;
}
.formCol form button {
  align-self: flex-start;
  width: 240px;
  border-radius: 25px;
}
