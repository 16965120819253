.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 0px 60px 0px;
}
.headingCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}
.headingCol h1 {
  text-align: start;
  align-self: flex-start;
}
.headingCol h4 {
    text-align: start;
    align-self: flex-start;
    color: rgb(94, 94, 94);
  }
.paraCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.paraCol p {
  text-align: start;
  align-self: flex-start;
}
.imgCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imgCont img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .main {
    padding: 30px 0px 30px 0px;
  }
}
