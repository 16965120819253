.main {
  padding: 40px 0px 40px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCol h1,
.contentCol h4,
.contentCol p,
.contentCol a {
  align-self: flex-start;
  text-align: start;
}
.contentCol h4 {
  color: #8a8a8a;
  font-weight: 400;
}
.contentCol h4 span {
  font-weight: 700;
  text-transform: none;
  color: black;
}
.buttonsCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: row;
  gap: 15px;
}
.buttonsCont button {
  text-transform: none;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.servicesCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.servicesCont p {
  color: #8a8a8a;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  align-self: center;
}
.service {
  height: 100%;
  min-width: 75px;
  max-width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.service img {
  width: 50px;
  height: 50px;
}
.videoCont {
  width: 95%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 992px) {
  .main {
    padding: 40px 0px 40px 0px;
  }
  .servicesCont {
    gap: 10px;
  }
}
