.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.bannerImage {
  width: 100%;
  min-height: 450px;
  max-height: 560px;
  max-width: 850px;
}
.box p,
.box h2 {
  text-align: start;
  align-self: flex-start;
  padding-right: 60px;
}
.box p {
  font-weight: 600;
}
.footer {
  align-self: flex-start;
  width: 43%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 2px solid #17191a;
  padding: 4px;
  border-radius: 25px;
  cursor: pointer;
}
.footer img {
  border-radius: 50%;
  border: 2px solid #17191a;
  padding: 7px;
  width: 40px;
  height: 40px;
}
.box:hover .content h2,
.box:hover .footer h4 {
  color: rgb(100, 100, 100);
}
.box:hover .footer {
  border: 2px solid rgb(85, 85, 85);
}
.box:hover .footer img {
  background-color: rgb(228, 228, 228);
  border: 2px solid rgb(65, 65, 65);
}
@media (max-width: 992px) {
  .footer {
    width: 65%;
  }
}
