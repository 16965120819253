.seamless-experience-ecom-wrapper {
    padding: 80px 0px 40px 0px;
    width: 100%;
}

.seamless-experience-ecom-wrapper .seamless-strategies-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 60px;
    padding: 0 100px;
    gap: 15px;
}

.seamless-experience-ecom-wrapper .seamless-strategies-wrapper .para_main {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.seamless-experience-ecom-wrapper .common-card-wrapper{
    background-color: #FAFAFA;
    border-radius: 14px; 
    padding: 65px 40px;
    height: calc(100% - 5px);
}

.seamless-experience-ecom-wrapper .common-card-wrapper .card-content {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 15px;
}

.seamless-experience-ecom-wrapper .common-card-wrapper .card-content .card-icon-wrapper{
    padding: 20px 15px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .seamless-experience-ecom-wrapper .seamless-strategies-wrapper {
        padding: 0 60px;
    }
}

@media screen and (max-width: 768px) {
    .seamless-experience-ecom-wrapper .seamless-strategies-wrapper {
        padding: 0 30px;
    }
}

@media screen and (max-width: 500px) {
    .seamless-experience-ecom-wrapper .seamless-strategies-wrapper {
        padding: 0;
    }
}