.main {
  padding: 100px 0px 100px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.boxContCol{
  gap: 15px;
}
.col1 h1 {
  align-self: flex-start;
  text-align: start;
}
.col1 p {
  text-align: start;
  align-self: flex-start;
}
.col1 a {
  text-align: start;
  align-self: flex-start;
}
.col2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  height: auto;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
  gap: 18px;
  box-shadow: 0 6px 40px rgb(0 0 0 / 0.2);
  border-radius: 12px;
} 
.box_height_auto {
  height: 100%;
}
.box img {
  width: 55px;
  height: 55px;
  align-self: center;
  text-align: center;
}
.box h3 {
  align-self: center;
  text-align: center;
}
.box p {
  align-self: center;
  text-align: center;
}
@media (max-width: 992px) {
  .col1 {
    gap: 15px;
  }
  .boxContCol{
    gap: 40px;
  }
  .col1 h1 {
    align-self: center;
    text-align: center;
  }
  .col1 p {
    text-align: center;
    align-self: center;
  }
  .col1 a {
    text-align: center;
    align-self: center;
  }
}
@media (max-width: 767px) {
  .main {
    padding: 40px 0px 40px 0px;
  }
  .col2 {
    justify-content: center;
    flex-direction: column;
  }
  .box img {
    width: 45px;
    height: 45px;
}
}
