.Hero {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  background: rgb(45, 45, 45);
}
.Hero_Content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  align-self: center;
}
.Hero_Content h1,
.Hero_Content h3,
.link {
  text-align: start;
  align-self: flex-start;
}
.Hero_Content h1 {
  font-weight: 900;
  color: white;
}
.link {
  padding-top: 20px;
}
.link a {
  padding-right: 6px;
  font-size: 16px;
  font-family: "MyFont";
  text-align: start;
  margin: 0;
  color: white;
}
.link img {
  width: 20px;
  height: 20px;
}
@media (max-width: 767px) {
  .Hero {
    height: 70vh;
  }
  .video {
    height: 70vh;
  }
}
@media (max-height: 600px) {
  .Hero {
    height: 100vh;
  }
  .video {
    height: 100vh;
  }
}
