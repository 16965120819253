.box {
  background-color: rgba(246, 246, 246, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 25px 50px 25px;
}
.white {
  background-color: white;
}
.box img,
.box p,
.box h3 {
  align-self: flex-start;
  text-align: start;
}
