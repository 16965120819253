.boxCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  max-width: 420px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 2px;
}
.box img {
  width: 100%;
  max-height: 335px;
}
.contentCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.contentCont p,
.contentCont h2,
.contentCont a {
  text-align: start;
  align-self: flex-start;
}
.link {
  width: 100%;
  padding-top: 10px;
}
.link a {
  text-decoration: 2px underline;
  text-underline-offset: 4px;
  padding-right: 6px;
  font-size: 16px;
  font-family: "MyFontRegular";
  margin: 0;
  color: rgb(0, 0, 0);
  font-weight: 800;
}
.link img {
  width: 20px;
  height: 20px;
}
@media (max-width: 1200px) {
  .box img {
    max-height: 260px;
  }
}
@media (max-width: 992px) {
  .box {
    max-width: 550px;
  }
  .box img {
    max-height: 400px;
  }
}
@media (max-width: 480px) {
  .box img {
    max-height: 300px;
  }
}
