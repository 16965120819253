.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 26px;
  min-height: 468px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.content {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}
.content img {
  width: 80px;
  height: 80px;
  background-color: rgba(102, 102, 102, 1);
  border-radius: 3px;
  padding: 20px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 2px solid #17191a;
  padding: 4px;
  border-radius: 25px;
  cursor: pointer;
}
.footer img {
  border-radius: 50%;
  border: 2px solid #17191a;
  padding: 7px;
  width: 40px;
  height: 40px;
}
.box:hover {
  background-color: #17191a;
}
.box:hover .content h2,
.box:hover .footer h4 {
  color: white;
}
.box:hover .footer {
  border: 2px solid white;
}
.box:hover .footer img {
  background-color: white;
}
@media (max-width: 992px) {
  .box {
    padding: 20px;
    min-height: 380px;
  }
}
