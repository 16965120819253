.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0px 100px 0px;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCol h1 {
  text-align: center;
}
.contentCol p {
  padding: 0px 15% 0px 15%;
}
.imgCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imgCont img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .main {
    padding: 40px 0px 40px 0px;
  }
}
